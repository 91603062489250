import React, { useMemo } from "react";

import { useFilters } from "@/providers/FiltersProvider";
import HeatSpotColours from "@/services/HeatSpotColours";

/**
 * The Gender Index Interactive Maps - Colour Key Chart
 */

interface IColourKey {
    percentage?: boolean;
}
function ColourKey({ percentage }: IColourKey) {
    /**
     * Get the gender name via the context
     */
    const { mainFilters } = useFilters();
    const genderName = useMemo(() => {
        return mainFilters?.["D001"]?.data[0]?.label;
    }, [mainFilters]);

    /**
     * Create the colour keys for each percent/number
     */
    const colourKey = () => {
        const colourKeyElements: Array<JSX.Element> = [];

        for (let i = 0; i < HeatSpotColours.gradientSteps; i++) {
            colourKeyElements.push(
                <div key={i} className="d-inline-block">
                    {HeatSpotColours.getHeatSpotSampleCircle(i)}
                </div>
            );
        }

        return colourKeyElements;
    };

    return (
        <div className="colourKey">
            <div className="tablekeyFooter">
                <div className="m-auto text-left key-text">
                    Fewer {percentage ? "% " : ""}{genderName ? genderName + "-" : ""}led
                </div>
                <div className="m-auto keyIndicators flexSpaceBtw">
                    {colourKey()}
                </div>
                <div className="m-auto text-right key-text">
                    More {percentage ? "% " : ""}{genderName ? genderName + "-" : ""}led
                </div>
            </div>
        </div>
    );
};

export default ColourKey;

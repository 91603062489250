import React, { useCallback, useMemo, useState } from "react";

import { faUpRightAndDownLeftFromCenter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MapDiagram from "@/features/explore/maps/MapDiagram/MapDiagram";
import { commaSeperatorFormat, formatAsPercentage, rankNumbers } from "@/helpers/common";
import useCheckMobileScreen from "@/hooks/useCheckMobileScreen";
import { useFilters } from "@/providers/FiltersProvider";
import HeatSpotColours from "@/services/HeatSpotColours";
import CustomModal from "@/shared/components/CustomModal";
import Popover from "@/shared/components/Popover";
import { IRegionsData } from "@/types/Filters";

import ColourKey from "./ColourKey";

interface IMapContainer {
    regionData: {
        data: IRegionsData[] | null;
        isLoading: boolean;
    };
    gender?: string;
    disableExpand?: boolean;
    disableZoom?: boolean;
    disableTooltip?: boolean;
    disableInfo?: boolean;
    percentage?: boolean;
}
function MapContainer({ regionData, gender, disableExpand, disableZoom, disableTooltip, disableInfo, percentage }: IMapContainer) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const { mainFilters } = useFilters();
    const [elRef, setElRef] = useState<any>();
    const [selectedMapData, setSelectedMapData] = useState<IRegionsData | undefined>();
    const isMobile = useCheckMobileScreen();

    const customStyles = useMemo(() => {
        return {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                height: "calc(100vh - 100px)",
                width: isMobile ? "96%" : "600px",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                zIndex: 100,
                background: "#EDF0F1",
                padding: "16px",
            },
        };
    }, [isMobile]);

    const processData = (locationsData: IRegionsData[]) => {
        const rankedNumbersArray = rankNumbers(locationsData?.map(location => (location.gender_percentage)));
        const multiplier = HeatSpotColours.gradientSteps / rankedNumbersArray.length;

        Array.from(document.querySelectorAll("g[data-code], path[data-code], polygon[data-code]") as unknown as HTMLCollectionOf<HTMLElement>).forEach(el => {
            el.style.removeProperty("fill");
        });

        locationsData.map((statistic: any, key) => {
            // console.log("statistic:-", statistic, rankedNumbersArray);
            const processLocation = (statistic: any) => {
                let colourNumber = 0;

                if (rankedNumbersArray.length <= 1 || (rankedNumbersArray.length === 2 && rankedNumbersArray[key] == 2)) {
                    colourNumber = 9;
                } else if (rankedNumbersArray.length === 2 && rankedNumbersArray[key] == 1) {
                    colourNumber = 0;
                } else {
                    colourNumber = Math.round((((rankedNumbersArray[key]) - 1) * multiplier) - 1);
                }
                Array.from(document.querySelectorAll(
                    `g[data-code="${statistic.code}"], path[data-code="${statistic.code}"], polygon[data-code="${statistic.code}"]`) as unknown as HTMLCollectionOf<HTMLElement>)
                    .forEach(el => {
                        el.onmouseenter = () => {
                            // console.log("el", {
                            //     x,
                            // });
                            // setElRef(x);
                            setElRef(el);
                            setSelectedMapData(statistic);
                            // console.log("el:-", el, statistic);

                        };
                        el.onmouseleave = (e) => {
                            e.stopPropagation();
                            setElRef(null);

                        };
                        el.style.fill = HeatSpotColours.getHeatSpotColourByNumber(colourNumber);
                    });
            };
            processLocation(statistic);
        });
    };

    const getMapDiagram = useCallback((svgId: string) => {
        if (!mainFilters) return null;
        if (regionData.data) {
            setTimeout(() => {
                processData(regionData?.data as any);
            }, 0);
            return <MapDiagram modalIsOpen={modalIsOpen} svgId={svgId} disableZoom={disableZoom} disableInfo={disableInfo} />;
        }
        return null;
    }, [disableInfo, disableZoom, mainFilters, modalIsOpen, regionData.data]);

    const handleCloseModal = useCallback(() => {
        setIsOpen(false);
        setElRef(null);
    }, []);

    const handleOpenModal = useCallback(() => {
        setIsOpen(true);
        setElRef(null);
    }, []);

    return (
        <>
            <div id="interactive-map">
                {
                    !disableExpand
                    && <span
                        className="expand-con"
                        onClick={handleOpenModal}>
                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                    </span>
                }
                <div className="Map">
                    <div className="map-container-col">
                        {
                            getMapDiagram("svg-main")
                        }
                    </div>
                    {
                        !disableTooltip
                        && <Popover
                            title={selectedMapData?.name}
                            show={!!elRef}
                            elRef={elRef}
                            setElRef={setElRef}>
                            <div>
                                {
                                    selectedMapData
                                    && <>
                                        <div>
                                            Total companies: {commaSeperatorFormat(selectedMapData?.company_count || "")}
                                        </div>
                                        <div>
                                            No. of {gender}-led: {commaSeperatorFormat(selectedMapData?.gender_count || "")}
                                            ({formatAsPercentage((selectedMapData.gender_percentage * 100).toFixed(2) as any)})
                                        </div>
                                    </>
                                }
                            </div>
                        </Popover>
                    }
                </div>

                {
                    !disableExpand
                    && <CustomModal
                        open={modalIsOpen}
                        handleClose={handleCloseModal}
                        style={customStyles}>
                        <div className="close-con">
                            <FontAwesomeIcon
                                icon={faXmark}
                                onClick={handleCloseModal} />
                        </div>
                        <div className="map-modal">
                            <div className="Map">
                                <div className="map-container-col">
                                    {
                                        getMapDiagram("svg-modal")
                                    }
                                </div>
                            </div>
                        </div>
                    </CustomModal>
                }
            </div>
            <ColourKey percentage={percentage} />
        </>
    );
}

export default MapContainer;

import React, { useEffect } from "react";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Portal from "./Portal";

interface IPopover {
    title?: string;
    children?: JSX.Element | JSX.Element[];
    show?: boolean;
    elRef?: any;
    setElRef: any;
}
function Popover({ title, children, show = false, setElRef, elRef }: IPopover) {

    useEffect(() => {
        const popoverRef = document.getElementById("popover");
        // console.log("popoverRef:-", popoverRef);
        if (elRef && popoverRef) {
            const property = elRef?.getBoundingClientRect();
            popoverRef.style.top = `${property.top+80}px`;
            popoverRef.style.left = `${property.left-250}px`;
        }
    }, [elRef]);
    return (
        <Portal>
            <div
                className="popover"
                id="popover"
                style={{ display: show ? "block" : "none",
                    pointerEvents:"none" }}>
                <div className="popover-head">
                    {title}
                    <div className="popover-head-close" onClick={() => setElRef(null)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </div>
                </div>
                <div className="popover-body">
                    {children}
                </div>
            </div>
        </Portal>
    );
}

export default Popover;
